.order__list-heading-text {
  color: var(--text-heading-color-oragne);
}
.order__list-title-text {
  color: var(--text-heading-color-primary);
}
.order__list-number-index {
  color: var(--text-heading-color-grey);
}
.order__list__buyer > img {
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 6px;
}
.order__list__buyer > span {
  color: var(--text-heading-color-grey);
  font-weight: 600;
}
.order__list__phone-number,
.order__list__email,
.order__list__productName,
.order__list__price,
.order__list__created-date {
  color: var(--text-color-grey);
}
.order__list__productName {
  width: 220px;
  position: relative;
}
.order__list__productName > span {
  position: absolute;
  white-space: nowrap;
  width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.order__payment > img {
  width: 38px;
  cursor: pointer;
}
