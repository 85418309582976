@import url(https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap);
.btn__clear__data, .btn__clear__data:hover, .btn__clear__data:focus{
    box-shadow: none;
    margin-right: 32px;
    border-radius: 30px;
}
@media screen and (min-width: 46.25em) and (max-width: 63.9375em) {
    .btn__clear__data{
        display: flex;
        margin-bottom: 26px;
    }
}
@media screen and (max-width: 46.1875em) {
    .btn__clear__data{
        display: flex;
        margin-bottom: 26px;

    }
}

.btn__sync__data,
.btn__sync__data:hover,
.btn__sync__data:focus {
  background-color: #f06a0f;
  box-shadow: none;
  margin-right: 32px;
  border-radius: 30px;
  border: none;
}
.datePicker {
  width: 550px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
.datePicker input {
  outline: none;
  border-radius: 8px;
  border: 1px solid slategrey;
  color: var(--text-color-grey);
  font-weight: 500;
}
.datePicker__startDay > div > b,
.datePicker__endDay > div > b {
  color: var(--text-heading-color-primary);
}
.sync__data-btn {
  border-radius: 30px;
  height: 30px;
  margin-top: auto;
  border: none;
  border-color: unset;
}
.sync__data-btn:hover {
  background-color: var(--text-heading-color-oragne);
}

.sync__data-btn-close {
  border-radius: 30px;
}

@media screen and (min-width: 46.25em) and (max-width: 63.9375em) {
  .btn__sync__data {
    margin-bottom: 26px;
  }
}

@media screen and (max-width: 46.1875em) {
  .datePicker {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
  }
  .datePicker input {
    width: 100%;
    margin-bottom: 16px;
  }
  .btn__sync__data {
    margin-bottom: 26px;
  }
}

.header {
  background-color: var(--bg-color);
}
.header__logo {
  display: flex;
  flex-direction: column;
  padding: 0;
}
.header__logo > img {
  width: 100px;
}
.header__logo > span {
  font-size: 1rem;
}
.navbar-light .navbar-brand {
  color: #ffffff;
  font-weight: 600;
}
.navbar-light .navbar-nav .nav-link {
  color: #ffffff;
  font-size: 1.2rem;
}
.navbar-light .navbar-nav .nav-link:first-child {
  font-size: 1.4rem;
  font-weight: 600;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #ffffff;
  font-weight: 600;
}
.navbar-light .navbar-nav .nav-link.active {
  color: #ffffff;
  font-weight: 600;
}
.header__logout-btn {
  border-radius: 30px;
  box-shadow: none;
  color: white;
}
.header__line {
  width: 100%;
  background-color: #f2f0f0;
  margin-bottom: 24px;
}
.header__line-list {
  display: flex;
  /* width: 1240px; */
  padding: 0;
  margin: 0 auto;
}
.header__line-list-item {
  list-style: none;
  text-align: center;
  /* flex: 0 0 33.3333333333%; */
  margin-right: 24px;
}
.header__line-list-item:hover {
  border-color: #f06a0f;
}
.header__line-list-item > a,
.header__line-list-item > button {
  border-radius: 0;
  border-bottom: 2px solid transparent;
  color: var(--text-heading-color-grey);
  text-decoration: none;
  position: relative;
  padding: 8px 0;
  display: inline-block;
}
.header__line-list-item > a:hover,
.header__line-list-item > button:hover,
.header__line-list-item > button:active,
.header__line-list-item > button:focus {
  color: var(--text-color-oragne);
  border-color: var(--text-color-oragne) !important;
}

.nav-link:focus,
#lazada-nav-dropdown:focus,
#lazada-nav-dropdown:active {
  color: var(--text-color-oragne) !important;
}

.wrap-dropdown a:hover {
  background-color: rgb(224, 224, 224);
}

.wrap-dropdown a:focus {
  background: var(--text-color-oragne) !important;
  background-color: var(--text-color-oragne) !important;
  color: white !important;
}

.wrap-dropdown .dropdown-item:focus {
  background: var(--text-color-oragne) !important;
  background-color: var(--text-color-oragne) !important;
  color: white !important;
}

.wrap-dropdown .btn-dropdown:focus {
  outline: 0;
  box-shadow: none !important;
}

.card {
  border-radius: 16px;
}
.refresh__data, .refresh__data:focus{
  border: 1px solid var(--btn-color);
  background-color: transparent;
  color: #07c3c3;
  box-shadow: none;
}
.refresh__data:hover{
  background-color: var(--btn-color);
  color: white;
  border: 1px solid var(--btn-color);
}
.card-body-info {
  flex: 1 1;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
}
.card-body-info > h5{
  color: var(--text-heading-color-primary);
}
.card-body-info > p{
  color: var(--text-heading-color-grey)
}
.card-body {
  display: flex;
  justify-content: space-between;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 20px;
  z-index: 1;
  border-radius: 15px;
  border: 1px solid var(--btn-color);

}
.refresh__data {
  border-radius: 16px;
  margin-top: 16px;
}
.card-text {
  font-size: 1.8rem;
}

@media (min-width: 1200px) {
  .col-xl-3 {
    flex: 0 0 auto;
    width: 20%;
  }
}
@media (max-width: 1200px) {
  .col-xl-3 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media screen and (max-width: 46.1875em) {
  .data__chart {
    width: 250px;
  }
}

.event__detail-title {
  margin: 36px 0 20px 0;
  color: var(--text-heading-color-oragne);
}
.event__detail_info {
  display: flex;
  justify-content: space-between;
}
.event__detail_info-text {
  flex: 1 1;
}
.event__detail_info-text {
  font-size: 1.2rem;
  color: var(--text-color-grey);
}
#event__detail-hot-data-text {
  font-size: 1.4rem;
  color: var(--text-heading-color-primary);
}
.event__detail_info-date-text {
  flex: 1 1;
}
.event__detail__info-userCreated-title{
  color:var(--text-heading-color-oragne) ;
  font-weight: 600;
  margin: 10px 0;
}
.event__detail_info-date-text p {
  font-size: 1.2rem;
  color: var(--text-color-grey);
}
.event__detail-info {
  margin-top: 46px;
}
.event__detail__info-user-avatar,
.product__photo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 4px;
  object-fit: cover;
}

.event__detail__info-userCreated {
  width: 350px;
  border: 3px solid var(--btn-color);
  border-radius: 16px;
  padding-left: 10px;
}

/* Product list */
.product__number-index {
  color: var(--text-heading-color-grey);
}
.product__heading-text {
  margin: 20px 0px;
  color: var(--text-heading-color-oragne);
}
.product__title-text {
  color: var(--text-heading-color-primary);
}
.product__name {
  position: relative;
  width: 180px;
  color: var(--text-color-grey);
}
.product__name > span {
  position: absolute;
  width: 70%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.product__price {
  color: var(--text-color-grey);
}
.product__description {
  position: relative;
  width: 200px;
  color: var(--text-color-grey);
}
.product__description > span {
  position: absolute;
  width: 60%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.product__date_Created {
  width: 120px;
  color: var(--text-color-grey);
}
.product__date_Created span {
  white-space: nowrap;
}
/* Product list */

.image-gallery-slide img {
  height: 400px;
}
.image-gallery-thumbnail-image {
  height: 54px;
  object-fit: cover;
}
@media screen and (max-width: 46.1875em) {
  .event__detail_info {
    display: flex;
    flex-direction: column;
  }
  .event__detail_info-text p {
    font-size: 1.2rem;
  }
  .event__detail_info-date-text p {
    font-size: 1.2rem;
  }
  /* Product list */
  .product__date_Created {
    width: 180px;
    white-space: nowrap;
  }
  .product__name {
    white-space: nowrap;
    width: 200px;
  }
  .product__name > span {
    position: static;
  }
}
.event__list-heading {
  color: var(--text-heading-color-oragne);
}
.event__list-title-text {
  color: var(--text-color-primary);
}
.event__list-mumber-index {
  color: var(--text-heading-color-grey);
}
.event__list__photos__name {
  width: 300px;
  position: relative;
}
.event__list__photos__name > img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: -10px;
  box-sizing: content-box;
  border: 2px solid #fff;
}
.event__list__photos__name > span {
  position: absolute;
  width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.event__list__total__product {
  text-align: center;
}
.event__list__photos__name,
.event__list__group-name,
.event__list__created-date,
.event__list__created-by-user-name {
  color: var(--text-color-grey);
}
.event__list__total__product,
.event__list__total__order {
  color: var(--text-heading-color-primary);
  border: 1px solid var(--btn-color);
  border-radius: 30px;
  width: 50%;
  margin: 0 auto;
}
.event__list__total__product > img,
.event__list__total__order > img {
  width: 24px;
  height: 24px;
  margin-right: 6px;
}

.scroll__detail__list-user {
  max-height: 300px;
  margin-bottom: 36px;
}
.scroll__detail__list-admin {
  max-height: 300px;
}
.group__detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.group__detail__info-layout {
  display: flex;
  align-items: center;
}
.group__detail__avatar > img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-left: 10px;
  object-fit: cover;
}
.group__detail__info {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  margin-left: 18px;
}
.group__detail__info-box {
  display: flex;
}
.group__detail__info-layout {
  display: flex;
  align-items: center;
}
.group__detail__info-name {
  font-size: 1.8rem;
  font-weight: bold;
  margin: 0;
  color: var(--text-heading-color-oragne);
}
.group__detail__info-active {
  display: flex;
  align-items: center;
}
.group__detail-active-img {
  width: 46px;
  height: 46px;
  margin-left: 13px;
}
.group__detail__created-date {
  color: var(--text-heading-color-grey);
}
.group__detail-actions {
  display: flex;
  flex-direction: column;
}
.group__detail-actions-watch-event-btn {
  background-color: transparent;
  color: var(--text-color-primary);
  border-color: var(--btn-color);
  border-radius: 30px;
  align-items: center;
  margin-bottom: 24px;
  width: 130px;
}
.group__detail-actions-watch-event-btn:hover {
  background-color: var(--text-heading-color-oragne);
  color: white;
  border-color: var(--text-heading-color-oragne);
}
.group__detail-actions-watch-event-btn > img {
  width: 24px;
  margin-right: 6px;
}
.group__update-status-btn {
  border-radius: 30px;
  align-items: center;
  margin-bottom: 24px;
  width: 130px;
}
.group__update-status-btn:focus {
  background-color: var(--btn-color);
  box-shadow: none;
}
.group__update-status-btn:hover {
  background-color: var(--text-heading-color-oragne);
}
/* style again for bootstrap */
.group__deatil__desc-title > button {
  color: var(--text-heading-color-primary);
  font-weight: 500;
  font-size: 1.2rem;
}
.group__deatil__desc-title > button:not(.collapsed) {
  color: var(--text-heading-color-primary);
  font-weight: 500;
  border-color: transparent;
}
/* style again for bootstrap */

.group__deatil__desc-content-text {
  color: var(--text-color-grey);
  font-size: 1.2rem;
}
.group__detail-list-item {
  margin-bottom: 36px;
}

/* List Admin */
.group__detail-admin-text-color {
  color: var(--text-color-grey);
}
.group__detail-admin-list,
.group__detail-member-list {
  margin: 24px 0;
  color: var(--text-heading-color-oragne);
}
/* List member */
.group__detail-members-text-color {
  color: var(--text-color-grey);
}

/* List member and admin */
.group__detail-name-avatar {
  color: var(--text-heading-color-grey);
}
.group__detail-name-avatar > img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 6px;
  object-fit: cover;
}

@media screen and (min-width: 46.25em) and (max-width: 63.9375em) {
  .group__detail__avatar > img {
    width: 140px;
    height: 140px;
    border-radius: 50%;
  }
  .group__detail__created-date {
    font-size: 1.2rem;
  }
  .group__deatil__desc-title > button {
    color: var(--text-heading-color-primary);
    font-weight: 500;
    font-size: 1rem;
  }
  .group__deatil__desc-content-text {
    color: var(--text-color-grey);
    font-size: 1rem;
  }
  .group__detail-actions {
    flex-direction: row;
    margin: 24px 0 0 12px;
  }
  .group__detail-actions-watch-event-btn {
    margin-right: 16px;
  }
}

@media screen and (max-width: 46.1875em) {
  .group__detail__info-layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  .group__detail__info-layout-1 {
    display: flex;
    justify-content: center;
  }
  .group__detail__created-date {
    font-size: 1.2rem;
  }
  .group__deatil__desc-title > button {
    color: var(--text-heading-color-primary);
    font-weight: 500;
    font-size: 1rem;
  }
  .group__deatil__desc-content-text {
    color: var(--text-color-grey);
    font-size: 1rem;
  }
  .group__detail-actions {
    flex-direction: row;
    margin: 12px auto;
  }
  .group__detail-actions-watch-event-btn {
    margin-right: 16px;
  }
}

.group__list-heading {
  color: var(--text-heading-color-oragne);
}
.group__list-title-text {
  color: var(--text-color-primary);
}
.group__list__name {
  width: 220px;
  position: relative;
}
.group__name-active {
  align-items: center;
}
.group__list__name > img {
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
  width: 32px;
  height: 32px;
}
.group__list-number-index {
  color: var(--text-color-grey);
  width: 20px;
}
.group__list-search::placeholder {
  color: var(--text-color-primary);
  border-color: aqua;
}
.group__list__name-text {
  width: 80%;
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text-color-grey);
  font-weight: 500;
}
.group__list__creation-date {
  text-align: center;
  color: var(--text-color-grey);
}
.group__list__desc {
  color: var(--text-color-grey);
  width: 300px;
  position: relative;
}
.group__list__desc > span {
  position: absolute;
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.group__list__admin {
  position: relative;
  width: 380px;
}
.group__list__admin > span {
  position: absolute;
  white-space: nowrap;
  width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text-color-grey);
}
.group_list__member,
.group__list__event {
  text-align: center;
}
.group__list__member-box {
  display: flex;
  margin: 0 auto;
  padding: 4px 10px;
  width: auto;
  border-radius: 30px;
  border: 1px solid var(--btn-color);
  color: var(--text-color-primary);
  justify-content: center;
}
.group__list__member-box > img {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 6px;
}
.group__list__event-box{
  display: flex;
  margin: 0 auto;
  padding: 4px 10px;
  border-radius: 30px;
  width: 70%;
  border: 1px solid var(--btn-color);
  color: var(--text-color-primary);
  justify-content: center;
}
.group__list__event-box > img {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 6px;
}
.group__list__status-icon > img{
  margin: 0 auto;
  width: 36px;
  height: 36px;
}



.item-col,
.loading {
  color: #808080;
}

.create-app-lazada .error-text {
  font-size: 12px;
}

.lazada__feature-heading {
  color: var(--text-heading-color-oragne);
}
.lazada__feature-choose-status {
  margin-right: 10px;
  color: var(--text-heading-color-grey);
}
.lazada__feature-select-status {
  color: var(--text-color-grey);
}
.lazada__feature-list-title {
  color: var(--text-heading-color-primary);
}
.lazada__feature__item-number-index {
  color: var(--text-heading-color-grey);
}
.lazada__feature__item-name-avatar > img {
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
  width: 32px;
  height: 32px;
}
.lazada__feature__item-name-avatar,
.lazada__feature__item-email,
.lazada__feature__item-phone,
.lazada__feature__item-createdDate {
  color: var(--text-color-grey);
}
.lazada__feature__item-schedulePrice {
  color: var(--text-heading-color-primary);
  font-weight: 600;
}
.lazada__feature__approve-btn {
  border-radius: 30px;
  margin-right: 10px;
}
.lazada__feature__approve-btn:hover,
.lazada__feature__approve-btn:focus {
  background-color: var(--btn-primary-color-hover);
  box-shadow: none;
}
.lazada__feature__cancel-btn,
.lazada__feature__cancel-btn:focus {
  border-radius: 30px;
  margin-right: 10px;
  box-shadow: none;
}
.lazada__feature__item-actions {
  display: flex;
  justify-content: flex-end;
}

p {
  margin-bottom: 0;
}
/* 
.table.lazada {
  word-break: break-word;
} */

.modal-refund input:focus {
  outline: none;
  box-shadow: none;
  border-color: #15cdca !important;
}

.modal-refund tbody {
  display: block;
  height: 200px;
}

.modal-refund thead,
.modal-refund tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.modal-refund thead {
  width: calc(100% - 1em);
}

.modal-refund tbody td:nth-child(1),
.modal-refund thead th:nth-child(1) {
  width: 10%;
}
.modal-refund tbody td:nth-child(2),
.modal-refund tbody td:nth-child(3),
.modal-refund thead th:nth-child(2),
.modal-refund thead th:nth-child(3) {
  width: 45%;
}

.modal-refund .table > :not(:first-child) {
  border-top: none !important;
}

.pagination-container {
  display: flex;
  list-style-type: none;
  justify-content: center;
}
.pagination-container .pagination-item {
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;
  transition: all 0.2s ease-in-out;
}
.pagination-container .pagination-item.dots:hover {
  background-color: transparent;
  cursor: default;
}
.pagination-container .pagination-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
  border-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}
.pagination-container .pagination-item.selected {
  background-color: #ff6903;
  border-color: #ff6903;
  color: white;
}
.pagination-container .pagination-item.disabled {
  pointer-events: none;
  color: gray;
}
.pagination-container .pagination-item.disabled:hover {
  background-color: transparent;
  cursor: default;
}

.invoice-package-type .group__list__creation-date {
  text-align: start;
}

@keyframes flash {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: #d9edf7;
  }
  100% {
    background-color: transparent;
  }
}

.flash {
  animation: flash 1s;
}

.invoice-package-detail .form-select-sm:focus {
  outline: none;
  border-color: #15cdca !important;
}

.history-log .group__list__creation-date,
.history-log .user__detail-group-list-mumber-index {
  text-align: start;
  color: #7c797a !important;
}

.liveReport {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.liveReport__datePicker {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}
.liveReport__datePicker-checkInDate {
  margin-right: 12px;
}
.liveReport__datePicker-checkInDate-title,
.liveReport__datePicker-checkOutDate-title {
  color: var(--text-heading-color-oragne);
  font-weight: 600;
}
.liveReport__datePicker-checkOutDate {
  margin-right: 12px;
}
.liveReport__datePicker-checkInDate-input,
.liveReport__datePicker-checkOutDate-input {
  border-color: var(--btn-color);
  color: var(--text-heading-color-grey);
  border-radius: 6px;
  outline: none;
}
.liveReport__datePicker-btn-sync {
  border-radius: 6px;
  margin-top: 22px;
}
.liveReport__datePicker-btn-sync:hover,
.liveReport__datePicker-btn-sync:focus {
  background-color: var(--text-heading-color-oragne);
  box-shadow: none;
}
.liveReport__datePicker-time-default {
  display: flex;
}
.liveReport__datePicker-time-from {
  margin-right: 12px;
}
.liveReport__datePicker-time-from,
.liveReport__datePicker-time-to {
  border: 2px solid var(--text-heading-color-primary);
  border-radius: 12px;

  height: 50px;
}
.liveReport__datePicker-time-from > p,
.liveReport__datePicker-time-to > p {
  margin: 10px;
  color: var(--text-heading-color-grey);
}
.liveReport__datePicker-time-from label {
  width: 44px;
}
.liveReport__newGroups {
  margin-top: 32px;
}
.liveReport__newGroups-heading {
  color: var(--text-heading-color-oragne);
}
.liveReport__newGroups-title {
  color: var(--text-heading-color-primary);
}
.liveReport__newGroups__name__avatar {
  width: 220px;
  position: relative;
}
.liveReport__newGroups__name__avatar > img {
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
  width: 32px;
  height: 32px;
}
.liveReport__newGroups__name__avatar > span {
  width: 80%;
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text-color-grey);
  font-weight: 500;
}
.liveReport__newGroups__created-date,
.liveReport__newGroups__description {
  color: var(--text-color-grey);
}
.liveReport__newGroups__status > img {
  margin: 0 auto;
  width: 36px;
  height: 36px;
}
.liveReport__newUsers {
  margin: 32px 0;
}
.liveReport__datePicker-time-from p {
  font-size: 18px;
}
.liveReport__datePicker-time-to p {
  font-size: 18px;
}
.liveReport__newUsers-layout {
  margin: 22px 0;
  text-align: center;
  display: flex;
  justify-content: center;
}
.liveReport__newUsers-avatar {
  width: 100%;
  height: 280px;
  object-fit: cover;
}
.liveReport__newUsers-item {
  flex-direction: column;
  border-radius: 0;
  border: none;
  color: var(--text-heading-color-grey);
}
.liveReport__newUsers-heading {
  color: var(--text-heading-color-oragne);
}
@media screen and (min-width: 46.25em) and (max-width: 63.9375em) {
  .liveReport__datePicker-time-default {
    flex-direction: column;
  }
  .liveReport__datePicker-time-from {
    margin-bottom: 12px;
  }
  .liveReport__datePicker {
    align-items: center;
  }
}

@media screen and (max-width: 46.1875em) {
  .liveReport {
    flex-direction: column;
  }
  .liveReport__datePicker {
    flex-direction: column;
  }
  .liveReport__datePicker-checkInDate input {
    width: 100%;
  }
  .liveReport__datePicker-checkOutDate input {
    width: 100%;
  }
  .liveReport__datePicker-time-from {
    margin-right: 6px;
  }
}

.order__list-heading-text {
  color: var(--text-heading-color-oragne);
}
.order__list-title-text {
  color: var(--text-heading-color-primary);
}
.order__list-number-index {
  color: var(--text-heading-color-grey);
}
.order__list__buyer > img {
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 6px;
}
.order__list__buyer > span {
  color: var(--text-heading-color-grey);
  font-weight: 600;
}
.order__list__phone-number,
.order__list__email,
.order__list__productName,
.order__list__price,
.order__list__created-date {
  color: var(--text-color-grey);
}
.order__list__productName {
  width: 220px;
  position: relative;
}
.order__list__productName > span {
  position: absolute;
  white-space: nowrap;
  width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.order__payment > img {
  width: 38px;
  cursor: pointer;
}

.subscription li {
  margin-bottom: 10px;
  list-style-image: url(/static/media/checkmark.dae38d34.svg);
}

.modal-add .modal-dialog {
  max-width: 600px;
}

.btn-payment-type:hover {
  background-color: #eaeaea !important;
  transition: all 0.3s ease-in-out;
}

.form-subscription .form-select {
  font-size: 12px;
  background-position: right 0.5rem center;
}

.form-subscription button:focus {
  box-shadow: none;
}

.uploaded-file {
  position: relative;
  display: inline-block;
  margin: 10px;
  border: 1px solid #ccc;
  padding: 10px;
  width: 300px;
  text-align: center;
}

.uploaded-file img {
  max-width: 100%;
}

.upload-progress {
  background-color: #e1e1e1;
  height: 5px;
  position: relative;
  margin-top: 10px;
  width: 100%;
}

.upload-progress-bar {
  background-color: #15cdca;
  height: 100%;
  width: 0;
  transition: width 0.3s;
}

.dropzone {
  border: 1px dashed #15cdca;
  padding: 1rem;
  cursor: pointer;
}

.custom-form-select:focus {
  outline: 0 !important;
}

.custom-form-select.bg-success,
.custom-form-select.bg-secondary {
  color: #fff;
}

.custom-form-select.bg-success > option,
.custom-form-select.bg-secondary > option {
  color: #000;
}

.table.lazada {
  th,
  td {
    vertical-align: middle;
  }
}

td {
  color: #808080;
}

.user__detail-layout-top {
  margin: 30px 0 20px 0;
  text-align: center;
}
.user__detail__avatar {
  display: flex;
  justify-content: center;
  position: relative;
}
.user__detail__avatar-img {
  width: 240px;
  height: 240px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid rgb(194, 187, 187);
}
.user__detail__name__created-date {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.user__detail__name__created-date > p {
  color: var(--text-heading-color-oragne);
  font-size: 2.5rem;
  font-weight: 600;
  margin: 0;
}
.user__detail__name__created-date > h3 {
  color: var(--text-heading-color-grey);
}
.user__detail__contact {
  margin-bottom: 16px;
}
.user__detail__contact-heading {
  color: var(--text-heading-color-oragne);
}
.user__detail__info-other,
.user__detail__contact-info,
.user__detail__premium-status {
  width: 100%;
  border-radius: 10px;
  border: 2px solid var(--btn-color);
  box-shadow: rgb(0 0 0 / 5%) 0px 0px 20px;
}
.user__detail__contact-info-box {
  padding: 10px;
  position: relative;
}
.user__detail__contact-info-box > p {
  margin-bottom: 6px;
  color: var(--text-heading-color-grey);
}
.user__detail__contact-info-emaill {
  width: 100%;
  position: relative;
}
.user__detail__contact-info-email > span {
  width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: absolute;
}
.user__detail__info {
  margin-bottom: 16px;
}
.user__detail__info-heading {
  color: var(--text-heading-color-oragne);
}
.user__detail__info-other-box {
  padding: 10px;
  color: var(--text-heading-color-grey);
}
.user__detail__premium {
  margin-bottom: 36px;
}
.user__detail__premium-heading {
  color: var(--text-heading-color-oragne);
}

.user__detail-schedule-event {
  padding: 6px 10px;
}
.user__detail-schedule-event-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user__detail-schedule-event-update {
  align-items: center;
  display: flex;
}
.user__detail-schedule-event-update > img {
  width: 42px;
}
.user__detail-schedule-event-update > b {
  margin-left: 10px;
  color: var(--text-heading-color-grey);
}
.user__detail-schedule-event-update-checkbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.user__detail-schedule-event-input:checked {
  background-color: var(--text-heading-color-primary);
  color: var(--text-heading-color-primary);
  border-color: var(--text-heading-color-primary);
}
.user__detail-schedule-event-input[disabled] {
  opacity: 1;
}
.user__detail-schedule-event-expiration-date {
  margin-bottom: 0;
  margin: 6px 0;
  color: var(--text-heading-color-grey);
  font-weight: 600;
}
/* premium account start */
.user__detail__premium {
  padding: 6px 10px;
  margin-bottom: 12px;
}
.user__detail__premium-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user__detail__premium-update {
  align-items: center;
  display: flex;
}
.user__detail__premium-update > img {
  width: 42px;
}
.user__detail__premium-update > b {
  margin-left: 10px;
  color: var(--text-heading-color-grey);
}
.user__detail__premium-update-checkbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.user__detail__premium-input:checked {
  background-color: var(--text-heading-color-primary);
  color: var(--text-heading-color-primary);
  border-color: var(--text-heading-color-primary);
}
.user__detail__premium-input[disabled] {
  opacity: 1;
}
.user__detail__premium-expiration-date {
  margin-bottom: 0;
  margin: 6px 0;
  color: var(--text-heading-color-grey);
  font-weight: 600;
}
/* premium account end */

/* Group of user */
.user__detail-group-list-heading {
  margin-bottom: 24px;
  color: var(--text-heading-color-oragne);
}
.user__detail-group-list-title {
  color: var(--text-heading-color-primary);
}

.user__detail-group-list-mumber-index {
  color: var(--text-heading-color-grey);
}
.user__detail-group-list-avatar-name {
  width: 220px;
  position: relative;
}
.user__detail-group-list-avatar-name > img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 6px;
  object-fit: cover;
}
.user__detail-group-list-avatar-name > span {
  position: absolute;
  white-space: nowrap;
  width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--text-heading-color-grey);
}
.user__detail-event-list-heading {
  margin: 24px 0;
  color: var(--text-heading-color-oragne);
}
.user__detail-group-list-description {
  width: 230px;
  position: relative;
}
.user__detail-group-list-description > span {
  width: 85%;
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text-color-grey);
}
.user__detail-group-list-created-date {
  color: var(--text-heading-color-grey);
}
.user__detail-group-list-to-watch-btn,
.user__detail-event-list-to-watch-btn {
  border-radius: 30px;
}
.user__detail-group-list-to-watch-btn:hover,
.user__detail-event-list-to-watch-btn:hover {
  background-color: var(--text-heading-color-oragne);
}
/* event of user  */
.user__detail-event-list-title {
  color: var(--text-heading-color-primary);
}
.user__detail-event-list-mumber-index {
  color: var(--text-heading-color-grey);
}
.user__detail-event-list-avatar-name {
  width: 220px;
  position: relative;
}
.user__detail-event-list-avatar-name > img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 6px;
  object-fit: cover;
}
.user__detail-event-list-avatar-name > span {
  position: absolute;
  white-space: nowrap;
  width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--text-heading-color-grey);
}
.user__detail-event-list-description {
  width: 230px;
  position: relative;
}
.user__detail-event-list-description > span {
  width: 85%;
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text-color-grey);
}
.user__detail-event-list-created-date,
.user__detail-event-list-group-name {
  color: var(--text-color-grey);
}
.user__detail-event-list-group-name {
  position: relative;
  width: 230px;
}
.user__detail-event-list-group-name > span {
  position: absolute;
  white-space: nowrap;
  width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
}



@media screen and (min-width: 46.25em) and (max-width: 63.9375em) {
  .user__detail__avatar-img {
    width: 180px;
    height: 180px;
  }
  /* Group of user  */
  .user__detail-group-list-avatar-name {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .user__detail-group-list-avatar-name > span {
    position: static;
  }
  .user__detail-group-list-avatar-name > img {
    margin: 0 0 6px 0;
  }
  /* Event of user  */
  .user__detail-event-list-avatar-name {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .user__detail-event-list-avatar-name > span {
    position: static;
  }
  .user__detail-event-list-avatar-name > img {
    margin: 0 0 6px 0;
  }
}

@media screen and (max-width: 46.1875em) {
  .user__info {
    margin-bottom: 24px;
  }
  .user__detail__avatar-img {
    width: 120px;
    height: 120px;
  }
  .user__detail__name__created-date {
    padding-left: 30px;
  }
  .user__detail__name__created-date > p {
    font-size: 1.8rem;
  }
  .user__detail__name__created-date > h3 {
    font-size: 1.4rem;
  }
}

.user__list-heading{
  color: var(--text-heading-color-oragne);
}
.user__list-title{
  color: var(--text-heading-color-primary);
}
.user__list-number-index{
  color: var(--text-heading-color-grey);
}
.user__list-name-avatar {
  color: var(--text-color-grey);
}
.user__list-name-avatar >img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 6px;
  object-fit: cover;
}
.user__list-email,
.user__list-phone-number,
.user__list-created-date {
  color: var(--text-color-grey);
}
.user__list-groups{
  width: 350px;
  position: relative;
}
.user__list-groups span {
  color: var(--text-color-grey);
}
.user__list-groupsp-name {
  position: absolute;
  white-space: nowrap;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}


.table-heading {
  color: var(--text-heading-color-oragne);
}
.table-choose-status {
  margin-right: 10px;
  color: var(--text-heading-color-grey);
}
.table-select-status {
  color: var(--text-color-grey);
}
.table-list-title {
  color: var(--text-heading-color-primary);
}
.table__index {
  color: var(--text-heading-color-grey);
}
.table__avatar > img {
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
  width: 32px;
  height: 32px;
}
.table__item {
  color: var(--text-color-grey);
}
.table__item-price {
  color: var(--text-heading-color-primary);
  font-weight: 600;
}
.approve-btn {
  border-radius: 30px;
  margin-right: 10px;
}
.approve-btn:hover,
.approve-btn:focus {
  background-color: var(--btn-primary-color-hover);
  box-shadow: none;
}
.cancel-btn,
.cancel-btn:focus {
  border-radius: 30px;
  margin-right: 10px;
  box-shadow: none;
}
.table__item-actions {
  display: flex;
  justify-content: flex-end;
}

.error-text {
  color: red;
}

.form-control:active,
.form-control:focus {
  border-color: var(--bg-color);
  box-shadow: none;
}

.form-check-input:checked {
  background-color: var(--btn-color);
  border-color: var(--btn-color);
}

.form-check-input:active,
.form-check-input:focus {
  box-shadow: none;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 8 8"%3E%3Ccircle r="3" fill="%23ffffff"/%3E%3C/svg%3E') !important;
}

.form-variations .error-text {
  font-size: 10px;
}

divider:after,
.divider:before {
  content: '';
  flex: 1 1;
  height: 1px;
  background: #eee;
}
.h-custom {
  height: calc(100% - 73px);
}
@media (max-width: 450px) {
  .h-custom {
    height: 100%;
  }
}
.invalidate__form > span {
  color: red;
  margin-left: 8px;
}
.invalidate__form > input {
  border: 1px solid red;
}
.bg-co-bee {
  background-color: var(--bg-color);
}
.Signin {
  padding: 24px;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 20px;
  width: 500px;
  background-color: white;
  border-radius: 15px;
}
.Signin__heading {
  text-align: center;
  margin-bottom: 32px;
  color: var(--text-color-oragne);
}
.signin__input-text {
  border-radius: 12px;
  background-color: #ebebeb;
}
.signin__input-text:focus {
  color: #212529;
  background-color: #fff;
  outline: 0;
  border-color: transparent;
  border: 1px solid var(--text-color-grey);
  box-shadow: none;
}
.signin__submit-btn {
  border-radius: 30px;
  width: 100%;
  background-color: rgb(7, 195, 195);
  border: none;
}
.signin__submit-btn:hover,
.signin__submit-btn:focus {
  background-color: var(--text-color-oragne);
  box-shadow: none;
}
.modal__opt-heading {
  color: var(--text-heading-color-oragne);
}
.moda__opt-input:focus {
  box-shadow: none;
  border: 1px solid var(--text-heading-color-grey);
}
.modal__opt-btn-login:focus {
  background-color: var(--btn-color);
  border: none;
  box-shadow: none;
}
.modal__opt-btn-login:hover:hover {
  background-color: var(--text-color-oragne);
}
.modal__errorMessage-heading {
  color: var(--text-heading-color-oragne);
}

body {
  margin: 0;
  font-family: 'Oxygen', sans-serif, 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --bg-color: #15cdca;
  --text-heading-color-oragne: #ff6903;
  --text-heading-color-primary: #51aaaa;
  --text-heading-color-grey: #7e7e7e;

  --text-color-oragne: #f06a0f;
  --text-color-primary: #079d9a;
  --text-color-grey: #7c797a;
  --btn-color: rgb(7, 195, 195);
  --btn-primary-color-hover: #0badad;
}

/* HTML: <div class="loader"></div> */
.loader {
  font-weight: bold;
  font-family: sans-serif;
  font-size: 20px;
  animation: l1 1s linear infinite alternate;
  text-align: center;
  margin: 200px auto auto auto;
  color: #808080;
}
.loader:before {
  content: 'Loading...';
}
@keyframes l1 {
  to {
    opacity: 0;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

/*  Set style agian for bootstrap */
table {
  border-collapse: separate;
  border-spacing: 0px;
}
.modal-content {
  border-radius: 15px;
}
.modal-header {
  color: var(--text-heading-color-oragne);
}
.pagination {
  font-weight: 700;
  font-size: 1.2rem;
}
.page-link {
  border: none;
  color: var(--text-heading-color-primary);
}
.page-link:hover {
  background-color: var(--text-heading-color-primary);
  color: white;
  border-radius: 6px;
}
.page-item.active .page-link {
  background-color: var(--text-heading-color-oragne);
  color: white;
  border-radius: 6px;
}
.page-link:focus {
  color: var(--text-heading-color-primary);
  background-color: transparent;
  box-shadow: none;
}

a {
  text-decoration: none;
  color: var(--text-color-grey);
}

a:hover {
  color: var(--text-color-oragne);
}

/*  Set style for bootstrap */

.btn-color-primary {
  background-color: var(--btn-color);
  box-shadow: none;
  border: none;
  margin-right: 4px;
}
.btn-color-grey {
  background-color: #b5b3b3;
  box-shadow: none;
  border: none;
}

.overlay__syncData > div > div {
  background: transparent;
  border: none;
}
.overlay__syncData-body {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 46.25em) and (max-width: 63.9375em) {
  .table {
    width: 1200px;
  }
  .scroll__mobile {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 46.1875em) {
  .table {
    width: 1200px;
  }
  .scroll__mobile {
    overflow-x: scroll;
  }
}

.custom-scrollbar {
  overflow: hidden scroll;
}

/* total width */
.custom-scrollbar::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
.custom-scrollbar::-webkit-scrollbar-button {
  display: none;
}

.f-14 {
  font-size: 14px !important;
}

.form-check-input:focus {
  box-shadow: none;
}

.form-check-input:focus:not(:checked) {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e") !important;
}

.form-check-input:checked {
  background-color: var(--bg-color);
  border-color: var(--bg-color);
}

button:focus {
  box-shadow: none !important;
}

.form-control:focus,
.form-select:focus {
  box-shadow: none !important;
  border-color: var(--bg-color);
}

.cursor-not-allowed {
  cursor: not-allowed;
}

