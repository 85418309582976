.card {
  border-radius: 16px;
}
.refresh__data, .refresh__data:focus{
  border: 1px solid var(--btn-color);
  background-color: transparent;
  color: #07c3c3;
  box-shadow: none;
}
.refresh__data:hover{
  background-color: var(--btn-color);
  color: white;
  border: 1px solid var(--btn-color);
}
.card-body-info {
  flex: 1;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
}
.card-body-info > h5{
  color: var(--text-heading-color-primary);
}
.card-body-info > p{
  color: var(--text-heading-color-grey)
}
.card-body {
  display: flex;
  justify-content: space-between;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 20px;
  z-index: 1;
  border-radius: 15px;
  border: 1px solid var(--btn-color);

}
.refresh__data {
  border-radius: 16px;
  margin-top: 16px;
}
.card-text {
  font-size: 1.8rem;
}

@media (min-width: 1200px) {
  .col-xl-3 {
    flex: 0 0 auto;
    width: 20%;
  }
}
@media (max-width: 1200px) {
  .col-xl-3 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media screen and (max-width: 46.1875em) {
  .data__chart {
    width: 250px;
  }
}
