.subscription li {
  margin-bottom: 10px;
  list-style-image: url('../../assets/images/checkmark.svg');
}

.modal-add .modal-dialog {
  max-width: 600px;
}

.btn-payment-type:hover {
  background-color: #eaeaea !important;
  transition: all 0.3s ease-in-out;
}

.form-subscription .form-select {
  font-size: 12px;
  background-position: right 0.5rem center;
}

.form-subscription button:focus {
  box-shadow: none;
}
