.user__detail-layout-top {
  margin: 30px 0 20px 0;
  text-align: center;
}
.user__detail__avatar {
  display: flex;
  justify-content: center;
  position: relative;
}
.user__detail__avatar-img {
  width: 240px;
  height: 240px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid rgb(194, 187, 187);
}
.user__detail__name__created-date {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.user__detail__name__created-date > p {
  color: var(--text-heading-color-oragne);
  font-size: 2.5rem;
  font-weight: 600;
  margin: 0;
}
.user__detail__name__created-date > h3 {
  color: var(--text-heading-color-grey);
}
.user__detail__contact {
  margin-bottom: 16px;
}
.user__detail__contact-heading {
  color: var(--text-heading-color-oragne);
}
.user__detail__info-other,
.user__detail__contact-info,
.user__detail__premium-status {
  width: 100%;
  border-radius: 10px;
  border: 2px solid var(--btn-color);
  box-shadow: rgb(0 0 0 / 5%) 0px 0px 20px;
}
.user__detail__contact-info-box {
  padding: 10px;
  position: relative;
}
.user__detail__contact-info-box > p {
  margin-bottom: 6px;
  color: var(--text-heading-color-grey);
}
.user__detail__contact-info-emaill {
  width: 100%;
  position: relative;
}
.user__detail__contact-info-email > span {
  width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: absolute;
}
.user__detail__info {
  margin-bottom: 16px;
}
.user__detail__info-heading {
  color: var(--text-heading-color-oragne);
}
.user__detail__info-other-box {
  padding: 10px;
  color: var(--text-heading-color-grey);
}
.user__detail__premium {
  margin-bottom: 36px;
}
.user__detail__premium-heading {
  color: var(--text-heading-color-oragne);
}

.user__detail-schedule-event {
  padding: 6px 10px;
}
.user__detail-schedule-event-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user__detail-schedule-event-update {
  align-items: center;
  display: flex;
}
.user__detail-schedule-event-update > img {
  width: 42px;
}
.user__detail-schedule-event-update > b {
  margin-left: 10px;
  color: var(--text-heading-color-grey);
}
.user__detail-schedule-event-update-checkbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.user__detail-schedule-event-input:checked {
  background-color: var(--text-heading-color-primary);
  color: var(--text-heading-color-primary);
  border-color: var(--text-heading-color-primary);
}
.user__detail-schedule-event-input[disabled] {
  opacity: 1;
}
.user__detail-schedule-event-expiration-date {
  margin-bottom: 0;
  margin: 6px 0;
  color: var(--text-heading-color-grey);
  font-weight: 600;
}
/* premium account start */
.user__detail__premium {
  padding: 6px 10px;
  margin-bottom: 12px;
}
.user__detail__premium-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user__detail__premium-update {
  align-items: center;
  display: flex;
}
.user__detail__premium-update > img {
  width: 42px;
}
.user__detail__premium-update > b {
  margin-left: 10px;
  color: var(--text-heading-color-grey);
}
.user__detail__premium-update-checkbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.user__detail__premium-input:checked {
  background-color: var(--text-heading-color-primary);
  color: var(--text-heading-color-primary);
  border-color: var(--text-heading-color-primary);
}
.user__detail__premium-input[disabled] {
  opacity: 1;
}
.user__detail__premium-expiration-date {
  margin-bottom: 0;
  margin: 6px 0;
  color: var(--text-heading-color-grey);
  font-weight: 600;
}
/* premium account end */

/* Group of user */
.user__detail-group-list-heading {
  margin-bottom: 24px;
  color: var(--text-heading-color-oragne);
}
.user__detail-group-list-title {
  color: var(--text-heading-color-primary);
}

.user__detail-group-list-mumber-index {
  color: var(--text-heading-color-grey);
}
.user__detail-group-list-avatar-name {
  width: 220px;
  position: relative;
}
.user__detail-group-list-avatar-name > img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 6px;
  object-fit: cover;
}
.user__detail-group-list-avatar-name > span {
  position: absolute;
  white-space: nowrap;
  width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--text-heading-color-grey);
}
.user__detail-event-list-heading {
  margin: 24px 0;
  color: var(--text-heading-color-oragne);
}
.user__detail-group-list-description {
  width: 230px;
  position: relative;
}
.user__detail-group-list-description > span {
  width: 85%;
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text-color-grey);
}
.user__detail-group-list-created-date {
  color: var(--text-heading-color-grey);
}
.user__detail-group-list-to-watch-btn,
.user__detail-event-list-to-watch-btn {
  border-radius: 30px;
}
.user__detail-group-list-to-watch-btn:hover,
.user__detail-event-list-to-watch-btn:hover {
  background-color: var(--text-heading-color-oragne);
}
/* event of user  */
.user__detail-event-list-title {
  color: var(--text-heading-color-primary);
}
.user__detail-event-list-mumber-index {
  color: var(--text-heading-color-grey);
}
.user__detail-event-list-avatar-name {
  width: 220px;
  position: relative;
}
.user__detail-event-list-avatar-name > img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 6px;
  object-fit: cover;
}
.user__detail-event-list-avatar-name > span {
  position: absolute;
  white-space: nowrap;
  width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--text-heading-color-grey);
}
.user__detail-event-list-description {
  width: 230px;
  position: relative;
}
.user__detail-event-list-description > span {
  width: 85%;
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text-color-grey);
}
.user__detail-event-list-created-date,
.user__detail-event-list-group-name {
  color: var(--text-color-grey);
}
.user__detail-event-list-group-name {
  position: relative;
  width: 230px;
}
.user__detail-event-list-group-name > span {
  position: absolute;
  white-space: nowrap;
  width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
}



@media screen and (min-width: 46.25em) and (max-width: 63.9375em) {
  .user__detail__avatar-img {
    width: 180px;
    height: 180px;
  }
  /* Group of user  */
  .user__detail-group-list-avatar-name {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .user__detail-group-list-avatar-name > span {
    position: static;
  }
  .user__detail-group-list-avatar-name > img {
    margin: 0 0 6px 0;
  }
  /* Event of user  */
  .user__detail-event-list-avatar-name {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .user__detail-event-list-avatar-name > span {
    position: static;
  }
  .user__detail-event-list-avatar-name > img {
    margin: 0 0 6px 0;
  }
}

@media screen and (max-width: 46.1875em) {
  .user__info {
    margin-bottom: 24px;
  }
  .user__detail__avatar-img {
    width: 120px;
    height: 120px;
  }
  .user__detail__name__created-date {
    padding-left: 30px;
  }
  .user__detail__name__created-date > p {
    font-size: 1.8rem;
  }
  .user__detail__name__created-date > h3 {
    font-size: 1.4rem;
  }
}
