.lazada__feature-heading {
  color: var(--text-heading-color-oragne);
}
.lazada__feature-choose-status {
  margin-right: 10px;
  color: var(--text-heading-color-grey);
}
.lazada__feature-select-status {
  color: var(--text-color-grey);
}
.lazada__feature-list-title {
  color: var(--text-heading-color-primary);
}
.lazada__feature__item-number-index {
  color: var(--text-heading-color-grey);
}
.lazada__feature__item-name-avatar > img {
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
  width: 32px;
  height: 32px;
}
.lazada__feature__item-name-avatar,
.lazada__feature__item-email,
.lazada__feature__item-phone,
.lazada__feature__item-createdDate {
  color: var(--text-color-grey);
}
.lazada__feature__item-schedulePrice {
  color: var(--text-heading-color-primary);
  font-weight: 600;
}
.lazada__feature__approve-btn {
  border-radius: 30px;
  margin-right: 10px;
}
.lazada__feature__approve-btn:hover,
.lazada__feature__approve-btn:focus {
  background-color: var(--btn-primary-color-hover);
  box-shadow: none;
}
.lazada__feature__cancel-btn,
.lazada__feature__cancel-btn:focus {
  border-radius: 30px;
  margin-right: 10px;
  box-shadow: none;
}
.lazada__feature__item-actions {
  display: flex;
  justify-content: flex-end;
}

p {
  margin-bottom: 0;
}
/* 
.table.lazada {
  word-break: break-word;
} */
