.btn__sync__data,
.btn__sync__data:hover,
.btn__sync__data:focus {
  background-color: #f06a0f;
  box-shadow: none;
  margin-right: 32px;
  border-radius: 30px;
  border: none;
}
.datePicker {
  width: 550px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
.datePicker input {
  outline: none;
  border-radius: 8px;
  border: 1px solid slategrey;
  color: var(--text-color-grey);
  font-weight: 500;
}
.datePicker__startDay > div > b,
.datePicker__endDay > div > b {
  color: var(--text-heading-color-primary);
}
.sync__data-btn {
  border-radius: 30px;
  height: 30px;
  margin-top: auto;
  border: none;
  border-color: unset;
}
.sync__data-btn:hover {
  background-color: var(--text-heading-color-oragne);
}

.sync__data-btn-close {
  border-radius: 30px;
}

@media screen and (min-width: 46.25em) and (max-width: 63.9375em) {
  .btn__sync__data {
    margin-bottom: 26px;
  }
}

@media screen and (max-width: 46.1875em) {
  .datePicker {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
  }
  .datePicker input {
    width: 100%;
    margin-bottom: 16px;
  }
  .btn__sync__data {
    margin-bottom: 26px;
  }
}
