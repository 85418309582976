divider:after,
.divider:before {
  content: '';
  flex: 1;
  height: 1px;
  background: #eee;
}
.h-custom {
  height: calc(100% - 73px);
}
@media (max-width: 450px) {
  .h-custom {
    height: 100%;
  }
}
.invalidate__form > span {
  color: red;
  margin-left: 8px;
}
.invalidate__form > input {
  border: 1px solid red;
}
.bg-co-bee {
  background-color: var(--bg-color);
}
.Signin {
  padding: 24px;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 20px;
  width: 500px;
  background-color: white;
  border-radius: 15px;
}
.Signin__heading {
  text-align: center;
  margin-bottom: 32px;
  color: var(--text-color-oragne);
}
.signin__input-text {
  border-radius: 12px;
  background-color: #ebebeb;
}
.signin__input-text:focus {
  color: #212529;
  background-color: #fff;
  outline: 0;
  border-color: transparent;
  border: 1px solid var(--text-color-grey);
  box-shadow: none;
}
.signin__submit-btn {
  border-radius: 30px;
  width: 100%;
  background-color: rgb(7, 195, 195);
  border: none;
}
.signin__submit-btn:hover,
.signin__submit-btn:focus {
  background-color: var(--text-color-oragne);
  box-shadow: none;
}
.modal__opt-heading {
  color: var(--text-heading-color-oragne);
}
.moda__opt-input:focus {
  box-shadow: none;
  border: 1px solid var(--text-heading-color-grey);
}
.modal__opt-btn-login:focus {
  background-color: var(--btn-color);
  border: none;
  box-shadow: none;
}
.modal__opt-btn-login:hover:hover {
  background-color: var(--text-color-oragne);
}
.modal__errorMessage-heading {
  color: var(--text-heading-color-oragne);
}
