.header {
  background-color: var(--bg-color);
}
.header__logo {
  display: flex;
  flex-direction: column;
  padding: 0;
}
.header__logo > img {
  width: 100px;
}
.header__logo > span {
  font-size: 1rem;
}
.navbar-light .navbar-brand {
  color: #ffffff;
  font-weight: 600;
}
.navbar-light .navbar-nav .nav-link {
  color: #ffffff;
  font-size: 1.2rem;
}
.navbar-light .navbar-nav .nav-link:first-child {
  font-size: 1.4rem;
  font-weight: 600;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #ffffff;
  font-weight: 600;
}
.navbar-light .navbar-nav .nav-link.active {
  color: #ffffff;
  font-weight: 600;
}
.header__logout-btn {
  border-radius: 30px;
  box-shadow: none;
  color: white;
}
.header__line {
  width: 100%;
  background-color: #f2f0f0;
  margin-bottom: 24px;
}
.header__line-list {
  display: flex;
  /* width: 1240px; */
  padding: 0;
  margin: 0 auto;
}
.header__line-list-item {
  list-style: none;
  text-align: center;
  /* flex: 0 0 33.3333333333%; */
  margin-right: 24px;
}
.header__line-list-item:hover {
  border-color: #f06a0f;
}
.header__line-list-item > a,
.header__line-list-item > button {
  border-radius: 0;
  border-bottom: 2px solid transparent;
  color: var(--text-heading-color-grey);
  text-decoration: none;
  position: relative;
  padding: 8px 0;
  display: inline-block;
}
.header__line-list-item > a:hover,
.header__line-list-item > button:hover,
.header__line-list-item > button:active,
.header__line-list-item > button:focus {
  color: var(--text-color-oragne);
  border-color: var(--text-color-oragne) !important;
}

.nav-link:focus,
#lazada-nav-dropdown:focus,
#lazada-nav-dropdown:active {
  color: var(--text-color-oragne) !important;
}

.wrap-dropdown a:hover {
  background-color: rgb(224, 224, 224);
}

.wrap-dropdown a:focus {
  background: var(--text-color-oragne) !important;
  background-color: var(--text-color-oragne) !important;
  color: white !important;
}

.wrap-dropdown .dropdown-item:focus {
  background: var(--text-color-oragne) !important;
  background-color: var(--text-color-oragne) !important;
  color: white !important;
}

.wrap-dropdown .btn-dropdown:focus {
  outline: 0;
  box-shadow: none !important;
}
