.btn__clear__data, .btn__clear__data:hover, .btn__clear__data:focus{
    box-shadow: none;
    margin-right: 32px;
    border-radius: 30px;
}
@media screen and (min-width: 46.25em) and (max-width: 63.9375em) {
    .btn__clear__data{
        display: flex;
        margin-bottom: 26px;
    }
}
@media screen and (max-width: 46.1875em) {
    .btn__clear__data{
        display: flex;
        margin-bottom: 26px;

    }
}
