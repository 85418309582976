.modal-refund input:focus {
  outline: none;
  box-shadow: none;
  border-color: #15cdca !important;
}

.modal-refund tbody {
  display: block;
  height: 200px;
}

.modal-refund thead,
.modal-refund tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.modal-refund thead {
  width: calc(100% - 1em);
}

.modal-refund tbody td:nth-child(1),
.modal-refund thead th:nth-child(1) {
  width: 10%;
}
.modal-refund tbody td:nth-child(2),
.modal-refund tbody td:nth-child(3),
.modal-refund thead th:nth-child(2),
.modal-refund thead th:nth-child(3) {
  width: 45%;
}

.modal-refund .table > :not(:first-child) {
  border-top: none !important;
}
