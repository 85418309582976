@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap');

body {
  margin: 0;
  font-family: 'Oxygen', sans-serif, 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --bg-color: #15cdca;
  --text-heading-color-oragne: #ff6903;
  --text-heading-color-primary: #51aaaa;
  --text-heading-color-grey: #7e7e7e;

  --text-color-oragne: #f06a0f;
  --text-color-primary: #079d9a;
  --text-color-grey: #7c797a;
  --btn-color: rgb(7, 195, 195);
  --btn-primary-color-hover: #0badad;
}

/* HTML: <div class="loader"></div> */
.loader {
  font-weight: bold;
  font-family: sans-serif;
  font-size: 20px;
  animation: l1 1s linear infinite alternate;
  text-align: center;
  margin: 200px auto auto auto;
  color: #808080;
}
.loader:before {
  content: 'Loading...';
}
@keyframes l1 {
  to {
    opacity: 0;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

/*  Set style agian for bootstrap */
table {
  border-collapse: separate;
  border-spacing: 0px;
}
.modal-content {
  border-radius: 15px;
}
.modal-header {
  color: var(--text-heading-color-oragne);
}
.pagination {
  font-weight: 700;
  font-size: 1.2rem;
}
.page-link {
  border: none;
  color: var(--text-heading-color-primary);
}
.page-link:hover {
  background-color: var(--text-heading-color-primary);
  color: white;
  border-radius: 6px;
}
.page-item.active .page-link {
  background-color: var(--text-heading-color-oragne);
  color: white;
  border-radius: 6px;
}
.page-link:focus {
  color: var(--text-heading-color-primary);
  background-color: transparent;
  box-shadow: none;
}

a {
  text-decoration: none;
  color: var(--text-color-grey);
}

a:hover {
  color: var(--text-color-oragne);
}

/*  Set style for bootstrap */

.btn-color-primary {
  background-color: var(--btn-color);
  box-shadow: none;
  border: none;
  margin-right: 4px;
}
.btn-color-grey {
  background-color: #b5b3b3;
  box-shadow: none;
  border: none;
}

.overlay__syncData > div > div {
  background: transparent;
  border: none;
}
.overlay__syncData-body {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 46.25em) and (max-width: 63.9375em) {
  .table {
    width: 1200px;
  }
  .scroll__mobile {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 46.1875em) {
  .table {
    width: 1200px;
  }
  .scroll__mobile {
    overflow-x: scroll;
  }
}

.custom-scrollbar {
  overflow: hidden scroll;
}

/* total width */
.custom-scrollbar::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
.custom-scrollbar::-webkit-scrollbar-button {
  display: none;
}

.f-14 {
  font-size: 14px !important;
}

.form-check-input:focus {
  box-shadow: none;
}

.form-check-input:focus:not(:checked) {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e") !important;
}

.form-check-input:checked {
  background-color: var(--bg-color);
  border-color: var(--bg-color);
}

button:focus {
  box-shadow: none !important;
}

.form-control:focus,
.form-select:focus {
  box-shadow: none !important;
  border-color: var(--bg-color);
}

.cursor-not-allowed {
  cursor: not-allowed;
}
