.group__list-heading {
  color: var(--text-heading-color-oragne);
}
.group__list-title-text {
  color: var(--text-color-primary);
}
.group__list__name {
  width: 220px;
  position: relative;
}
.group__name-active {
  align-items: center;
}
.group__list__name > img {
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
  width: 32px;
  height: 32px;
}
.group__list-number-index {
  color: var(--text-color-grey);
  width: 20px;
}
.group__list-search::placeholder {
  color: var(--text-color-primary);
  border-color: aqua;
}
.group__list__name-text {
  width: 80%;
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text-color-grey);
  font-weight: 500;
}
.group__list__creation-date {
  text-align: center;
  color: var(--text-color-grey);
}
.group__list__desc {
  color: var(--text-color-grey);
  width: 300px;
  position: relative;
}
.group__list__desc > span {
  position: absolute;
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.group__list__admin {
  position: relative;
  width: 380px;
}
.group__list__admin > span {
  position: absolute;
  white-space: nowrap;
  width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text-color-grey);
}
.group_list__member,
.group__list__event {
  text-align: center;
}
.group__list__member-box {
  display: flex;
  margin: 0 auto;
  padding: 4px 10px;
  width: auto;
  border-radius: 30px;
  border: 1px solid var(--btn-color);
  color: var(--text-color-primary);
  justify-content: center;
}
.group__list__member-box > img {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 6px;
}
.group__list__event-box{
  display: flex;
  margin: 0 auto;
  padding: 4px 10px;
  border-radius: 30px;
  width: 70%;
  border: 1px solid var(--btn-color);
  color: var(--text-color-primary);
  justify-content: center;
}
.group__list__event-box > img {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 6px;
}
.group__list__status-icon > img{
  margin: 0 auto;
  width: 36px;
  height: 36px;
}


