.uploaded-file {
  position: relative;
  display: inline-block;
  margin: 10px;
  border: 1px solid #ccc;
  padding: 10px;
  width: 300px;
  text-align: center;
}

.uploaded-file img {
  max-width: 100%;
}

.upload-progress {
  background-color: #e1e1e1;
  height: 5px;
  position: relative;
  margin-top: 10px;
  width: 100%;
}

.upload-progress-bar {
  background-color: #15cdca;
  height: 100%;
  width: 0;
  transition: width 0.3s;
}

.dropzone {
  border: 1px dashed #15cdca;
  padding: 1rem;
  cursor: pointer;
}

.custom-form-select:focus {
  outline: 0 !important;
}

.custom-form-select.bg-success,
.custom-form-select.bg-secondary {
  color: #fff;
}

.custom-form-select.bg-success > option,
.custom-form-select.bg-secondary > option {
  color: #000;
}

.table.lazada {
  th,
  td {
    vertical-align: middle;
  }
}

td {
  color: #808080;
}
