.invoice-package-type .group__list__creation-date {
  text-align: start;
}

@keyframes flash {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: #d9edf7;
  }
  100% {
    background-color: transparent;
  }
}

.flash {
  animation: flash 1s;
}

.invoice-package-detail .form-select-sm:focus {
  outline: none;
  border-color: #15cdca !important;
}

.history-log .group__list__creation-date,
.history-log .user__detail-group-list-mumber-index {
  text-align: start;
  color: #7c797a !important;
}
