.liveReport {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.liveReport__datePicker {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}
.liveReport__datePicker-checkInDate {
  margin-right: 12px;
}
.liveReport__datePicker-checkInDate-title,
.liveReport__datePicker-checkOutDate-title {
  color: var(--text-heading-color-oragne);
  font-weight: 600;
}
.liveReport__datePicker-checkOutDate {
  margin-right: 12px;
}
.liveReport__datePicker-checkInDate-input,
.liveReport__datePicker-checkOutDate-input {
  border-color: var(--btn-color);
  color: var(--text-heading-color-grey);
  border-radius: 6px;
  outline: none;
}
.liveReport__datePicker-btn-sync {
  border-radius: 6px;
  margin-top: 22px;
}
.liveReport__datePicker-btn-sync:hover,
.liveReport__datePicker-btn-sync:focus {
  background-color: var(--text-heading-color-oragne);
  box-shadow: none;
}
.liveReport__datePicker-time-default {
  display: flex;
}
.liveReport__datePicker-time-from {
  margin-right: 12px;
}
.liveReport__datePicker-time-from,
.liveReport__datePicker-time-to {
  border: 2px solid var(--text-heading-color-primary);
  border-radius: 12px;

  height: 50px;
}
.liveReport__datePicker-time-from > p,
.liveReport__datePicker-time-to > p {
  margin: 10px;
  color: var(--text-heading-color-grey);
}
.liveReport__datePicker-time-from label {
  width: 44px;
}
.liveReport__newGroups {
  margin-top: 32px;
}
.liveReport__newGroups-heading {
  color: var(--text-heading-color-oragne);
}
.liveReport__newGroups-title {
  color: var(--text-heading-color-primary);
}
.liveReport__newGroups__name__avatar {
  width: 220px;
  position: relative;
}
.liveReport__newGroups__name__avatar > img {
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
  width: 32px;
  height: 32px;
}
.liveReport__newGroups__name__avatar > span {
  width: 80%;
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text-color-grey);
  font-weight: 500;
}
.liveReport__newGroups__created-date,
.liveReport__newGroups__description {
  color: var(--text-color-grey);
}
.liveReport__newGroups__status > img {
  margin: 0 auto;
  width: 36px;
  height: 36px;
}
.liveReport__newUsers {
  margin: 32px 0;
}
.liveReport__datePicker-time-from p {
  font-size: 18px;
}
.liveReport__datePicker-time-to p {
  font-size: 18px;
}
.liveReport__newUsers-layout {
  margin: 22px 0;
  text-align: center;
  display: flex;
  justify-content: center;
}
.liveReport__newUsers-avatar {
  width: 100%;
  height: 280px;
  object-fit: cover;
}
.liveReport__newUsers-item {
  flex-direction: column;
  border-radius: 0;
  border: none;
  color: var(--text-heading-color-grey);
}
.liveReport__newUsers-heading {
  color: var(--text-heading-color-oragne);
}
@media screen and (min-width: 46.25em) and (max-width: 63.9375em) {
  .liveReport__datePicker-time-default {
    flex-direction: column;
  }
  .liveReport__datePicker-time-from {
    margin-bottom: 12px;
  }
  .liveReport__datePicker {
    align-items: center;
  }
}

@media screen and (max-width: 46.1875em) {
  .liveReport {
    flex-direction: column;
  }
  .liveReport__datePicker {
    flex-direction: column;
  }
  .liveReport__datePicker-checkInDate input {
    width: 100%;
  }
  .liveReport__datePicker-checkOutDate input {
    width: 100%;
  }
  .liveReport__datePicker-time-from {
    margin-right: 6px;
  }
}
