.user__list-heading{
  color: var(--text-heading-color-oragne);
}
.user__list-title{
  color: var(--text-heading-color-primary);
}
.user__list-number-index{
  color: var(--text-heading-color-grey);
}
.user__list-name-avatar {
  color: var(--text-color-grey);
}
.user__list-name-avatar >img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 6px;
  object-fit: cover;
}
.user__list-email,
.user__list-phone-number,
.user__list-created-date {
  color: var(--text-color-grey);
}
.user__list-groups{
  width: 350px;
  position: relative;
}
.user__list-groups span {
  color: var(--text-color-grey);
}
.user__list-groupsp-name {
  position: absolute;
  white-space: nowrap;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}

