.event__detail-title {
  margin: 36px 0 20px 0;
  color: var(--text-heading-color-oragne);
}
.event__detail_info {
  display: flex;
  justify-content: space-between;
}
.event__detail_info-text {
  flex: 1;
}
.event__detail_info-text {
  font-size: 1.2rem;
  color: var(--text-color-grey);
}
#event__detail-hot-data-text {
  font-size: 1.4rem;
  color: var(--text-heading-color-primary);
}
.event__detail_info-date-text {
  flex: 1;
}
.event__detail__info-userCreated-title{
  color:var(--text-heading-color-oragne) ;
  font-weight: 600;
  margin: 10px 0;
}
.event__detail_info-date-text p {
  font-size: 1.2rem;
  color: var(--text-color-grey);
}
.event__detail-info {
  margin-top: 46px;
}
.event__detail__info-user-avatar,
.product__photo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 4px;
  object-fit: cover;
}

.event__detail__info-userCreated {
  width: 350px;
  border: 3px solid var(--btn-color);
  border-radius: 16px;
  padding-left: 10px;
}

/* Product list */
.product__number-index {
  color: var(--text-heading-color-grey);
}
.product__heading-text {
  margin: 20px 0px;
  color: var(--text-heading-color-oragne);
}
.product__title-text {
  color: var(--text-heading-color-primary);
}
.product__name {
  position: relative;
  width: 180px;
  color: var(--text-color-grey);
}
.product__name > span {
  position: absolute;
  width: 70%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.product__price {
  color: var(--text-color-grey);
}
.product__description {
  position: relative;
  width: 200px;
  color: var(--text-color-grey);
}
.product__description > span {
  position: absolute;
  width: 60%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.product__date_Created {
  width: 120px;
  color: var(--text-color-grey);
}
.product__date_Created span {
  white-space: nowrap;
}
/* Product list */

.image-gallery-slide img {
  height: 400px;
}
.image-gallery-thumbnail-image {
  height: 54px;
  object-fit: cover;
}
@media screen and (max-width: 46.1875em) {
  .event__detail_info {
    display: flex;
    flex-direction: column;
  }
  .event__detail_info-text p {
    font-size: 1.2rem;
  }
  .event__detail_info-date-text p {
    font-size: 1.2rem;
  }
  /* Product list */
  .product__date_Created {
    width: 180px;
    white-space: nowrap;
  }
  .product__name {
    white-space: nowrap;
    width: 200px;
  }
  .product__name > span {
    position: static;
  }
}