.scroll__detail__list-user {
  max-height: 300px;
  margin-bottom: 36px;
}
.scroll__detail__list-admin {
  max-height: 300px;
}
.group__detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.group__detail__info-layout {
  display: flex;
  align-items: center;
}
.group__detail__avatar > img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-left: 10px;
  object-fit: cover;
}
.group__detail__info {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  margin-left: 18px;
}
.group__detail__info-box {
  display: flex;
}
.group__detail__info-layout {
  display: flex;
  align-items: center;
}
.group__detail__info-name {
  font-size: 1.8rem;
  font-weight: bold;
  margin: 0;
  color: var(--text-heading-color-oragne);
}
.group__detail__info-active {
  display: flex;
  align-items: center;
}
.group__detail-active-img {
  width: 46px;
  height: 46px;
  margin-left: 13px;
}
.group__detail__created-date {
  color: var(--text-heading-color-grey);
}
.group__detail-actions {
  display: flex;
  flex-direction: column;
}
.group__detail-actions-watch-event-btn {
  background-color: transparent;
  color: var(--text-color-primary);
  border-color: var(--btn-color);
  border-radius: 30px;
  align-items: center;
  margin-bottom: 24px;
  width: 130px;
}
.group__detail-actions-watch-event-btn:hover {
  background-color: var(--text-heading-color-oragne);
  color: white;
  border-color: var(--text-heading-color-oragne);
}
.group__detail-actions-watch-event-btn > img {
  width: 24px;
  margin-right: 6px;
}
.group__update-status-btn {
  border-radius: 30px;
  align-items: center;
  margin-bottom: 24px;
  width: 130px;
}
.group__update-status-btn:focus {
  background-color: var(--btn-color);
  box-shadow: none;
}
.group__update-status-btn:hover {
  background-color: var(--text-heading-color-oragne);
}
/* style again for bootstrap */
.group__deatil__desc-title > button {
  color: var(--text-heading-color-primary);
  font-weight: 500;
  font-size: 1.2rem;
}
.group__deatil__desc-title > button:not(.collapsed) {
  color: var(--text-heading-color-primary);
  font-weight: 500;
  border-color: transparent;
}
/* style again for bootstrap */

.group__deatil__desc-content-text {
  color: var(--text-color-grey);
  font-size: 1.2rem;
}
.group__detail-list-item {
  margin-bottom: 36px;
}

/* List Admin */
.group__detail-admin-text-color {
  color: var(--text-color-grey);
}
.group__detail-admin-list,
.group__detail-member-list {
  margin: 24px 0;
  color: var(--text-heading-color-oragne);
}
/* List member */
.group__detail-members-text-color {
  color: var(--text-color-grey);
}

/* List member and admin */
.group__detail-name-avatar {
  color: var(--text-heading-color-grey);
}
.group__detail-name-avatar > img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 6px;
  object-fit: cover;
}

@media screen and (min-width: 46.25em) and (max-width: 63.9375em) {
  .group__detail__avatar > img {
    width: 140px;
    height: 140px;
    border-radius: 50%;
  }
  .group__detail__created-date {
    font-size: 1.2rem;
  }
  .group__deatil__desc-title > button {
    color: var(--text-heading-color-primary);
    font-weight: 500;
    font-size: 1rem;
  }
  .group__deatil__desc-content-text {
    color: var(--text-color-grey);
    font-size: 1rem;
  }
  .group__detail-actions {
    flex-direction: row;
    margin: 24px 0 0 12px;
  }
  .group__detail-actions-watch-event-btn {
    margin-right: 16px;
  }
}

@media screen and (max-width: 46.1875em) {
  .group__detail__info-layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  .group__detail__info-layout-1 {
    display: flex;
    justify-content: center;
  }
  .group__detail__created-date {
    font-size: 1.2rem;
  }
  .group__deatil__desc-title > button {
    color: var(--text-heading-color-primary);
    font-weight: 500;
    font-size: 1rem;
  }
  .group__deatil__desc-content-text {
    color: var(--text-color-grey);
    font-size: 1rem;
  }
  .group__detail-actions {
    flex-direction: row;
    margin: 12px auto;
  }
  .group__detail-actions-watch-event-btn {
    margin-right: 16px;
  }
}
