.table-heading {
  color: var(--text-heading-color-oragne);
}
.table-choose-status {
  margin-right: 10px;
  color: var(--text-heading-color-grey);
}
.table-select-status {
  color: var(--text-color-grey);
}
.table-list-title {
  color: var(--text-heading-color-primary);
}
.table__index {
  color: var(--text-heading-color-grey);
}
.table__avatar > img {
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
  width: 32px;
  height: 32px;
}
.table__item {
  color: var(--text-color-grey);
}
.table__item-price {
  color: var(--text-heading-color-primary);
  font-weight: 600;
}
.approve-btn {
  border-radius: 30px;
  margin-right: 10px;
}
.approve-btn:hover,
.approve-btn:focus {
  background-color: var(--btn-primary-color-hover);
  box-shadow: none;
}
.cancel-btn,
.cancel-btn:focus {
  border-radius: 30px;
  margin-right: 10px;
  box-shadow: none;
}
.table__item-actions {
  display: flex;
  justify-content: flex-end;
}

.error-text {
  color: red;
}

.form-control:active,
.form-control:focus {
  border-color: var(--bg-color);
  box-shadow: none;
}

.form-check-input:checked {
  background-color: var(--btn-color);
  border-color: var(--btn-color);
}

.form-check-input:active,
.form-check-input:focus {
  box-shadow: none;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 8 8"%3E%3Ccircle r="3" fill="%23ffffff"/%3E%3C/svg%3E') !important;
}

.form-variations .error-text {
  font-size: 10px;
}
