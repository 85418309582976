.pagination-container {
  display: flex;
  list-style-type: none;
  justify-content: center;
}
.pagination-container .pagination-item {
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;
  transition: all 0.2s ease-in-out;
}
.pagination-container .pagination-item.dots:hover {
  background-color: transparent;
  cursor: default;
}
.pagination-container .pagination-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
  border-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}
.pagination-container .pagination-item.selected {
  background-color: #ff6903;
  border-color: #ff6903;
  color: white;
}
.pagination-container .pagination-item.disabled {
  pointer-events: none;
  color: gray;
}
.pagination-container .pagination-item.disabled:hover {
  background-color: transparent;
  cursor: default;
}
