.event__list-heading {
  color: var(--text-heading-color-oragne);
}
.event__list-title-text {
  color: var(--text-color-primary);
}
.event__list-mumber-index {
  color: var(--text-heading-color-grey);
}
.event__list__photos__name {
  width: 300px;
  position: relative;
}
.event__list__photos__name > img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: -10px;
  box-sizing: content-box;
  border: 2px solid #fff;
}
.event__list__photos__name > span {
  position: absolute;
  width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.event__list__total__product {
  text-align: center;
}
.event__list__photos__name,
.event__list__group-name,
.event__list__created-date,
.event__list__created-by-user-name {
  color: var(--text-color-grey);
}
.event__list__total__product,
.event__list__total__order {
  color: var(--text-heading-color-primary);
  border: 1px solid var(--btn-color);
  border-radius: 30px;
  width: 50%;
  margin: 0 auto;
}
.event__list__total__product > img,
.event__list__total__order > img {
  width: 24px;
  height: 24px;
  margin-right: 6px;
}
